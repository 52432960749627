import * as React from "react"
import Layout from "../components/layout"

const IndexPage = () => (
  <Layout>
    <div class="overflow-x-hidden bg-gradient-to-b from-yellow-500 ">
      <div class="px-6 py-8">
        <div class="max-w-7xl  container flex justify-between mx-auto radius-md">
          <div class="container overflow-x-hidden  ">
            <div class="text-xl text-center  p-10 justify-center">
              <h1 class="text-xl text-red-800 text-center  p-10 justify-center text-5xl	">
                Pastor Michael Mitau
              </h1>
              <p class="mt-10 text-base leading-normal text-center text-black text-2xl	">
                Pastor Michael Mitau was raised in a Christian home and gave his
                life to Jesus during his years of study in university. He was
                ordained as a pastor in 2017 and became the under shepherd of
                Calvary Chapel Nairobi at the end of 2019.
                 Michael is a father of three children.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage
